import type { ContractMetricsValidator, ContractValidator } from '@trustblock/validators/contract.validators';
import type { DistributedOmit } from './global.types';

export enum Chain {
  Ethereum = 'ethereum',
  Polygon = 'polygon',
  Avalanche = 'avalanche',
  Bnbchain = 'bnbchain',
  Arbitrum = 'arbitrum',
  Optimism = 'optimism',
  Fantom = 'fantom',
  Zksync = 'zksync'
}
export const chains = Object.values(Chain);

export enum ContractType {
  OnChain = 'on_chain',
  OffChain = 'off_chain'
}
export const contractTypes = Object.values(ContractType);

export interface ContractsCount {
  total: number;
  offChain: number;
  [Chain.Arbitrum]: number;
  [Chain.Avalanche]: number;
  [Chain.Bnbchain]: number;
  [Chain.Ethereum]: number;
  [Chain.Fantom]: number;
  [Chain.Optimism]: number;
  [Chain.Polygon]: number;
  [Chain.Zksync]: number;
}

export type ContractMetricsPrivate = ContractMetricsValidator;

export type ContractPrivate = DistributedOmit<ContractValidator, 'createdAt'> & {
  metrics: Omit<ContractMetricsValidator, 'contractId'>;
  createdAt: number;
};
export type ContractPrivateDeterministic<T extends ContractType> = ContractPrivate & {
  type: T;
};
export type ContractPublic = DistributedOmit<
  ContractValidator,
  'id' | 'projectId' | 'repositoryFullUrlHash' | 'createdAt'
> & {
  createdAt: number;
};
export type ContractPublicDeterministic<T extends ContractType> = ContractPublic & {
  type: T;
};
